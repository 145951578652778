.button {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  padding: 0 16px;
  white-space: nowrap;
}
