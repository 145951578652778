@use "../../../../stylesheet/00_settings/color" as colors;

.menu-grid-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 200px;
  overflow: hidden;

  & > div:not(:first-of-type) {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > div {
      display: flex;
      justify-content: space-between;
      gap: 24px;
    }

    & > div:nth-child(2) {
      flex: 1;

      > *:last-child {
        align-self: flex-end;
      }
    }
  }

  & div:first-of-type {
    overflow: hidden;
    border-radius: 8px;

    & > img {
      width: 100%;
      aspect-ratio: 2;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
      transition: transform ease-in-out 0.1s;

      &:hover {
        transform: scale(1.3);
      }
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 700;
    color: map-get(colors.$text, primary)
  }

  &__price {
    font-size: 14px;
    font-weight: 700;
    color: map-get(colors.$text, primary);
    white-space: nowrap;
  }

  &__description {
    font-size: 11px;
    font-weight: 400;
    color: map-get(colors.$text, gray)
  }

  &__add {
    color: map-get(colors.$text, gray);
    min-width: 24px;
    align-self: flex-end;
    cursor: pointer;
  }

  &__quantity {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 4px;

    > svg {
      color: map-get(colors.$accent, primary);
      align-self: center;
      cursor: pointer;
    }
  }
}