.not-found {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__title {
    font-size: clamp(48px, 35vw, 300px);
    line-height: 100%;
  }

  &__button {
    width: 180px !important;
    margin-top: 48px;
  }

  & > span {
    font-size: 12px;
    width: clamp(250px, 35vw, 400px);
    text-align: center;
    margin-top: 24px;
  }
}
