.form-input {
  width: 100%;
  min-height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  & > svg:first-of-type {
    position: absolute;
    margin-left: 8px;
  }

  & > svg:not(:first-of-type) {
    cursor: pointer;
    margin-right: 8px;
  }

  & input {
    width: 100%;
    border: none;
    outline: none;
    font: inherit;
    background: inherit;
    color: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    padding: 8px 12px;
    min-height: 40px;
    border-radius: 8px;
  }

  & input::placeholder {
    opacity: 0.8;
    color: white;
    font-weight: 200;
  }
}
