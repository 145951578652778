@use '../../stylesheet/01_tools/mixin' as mixins;
@use '../../stylesheet/00_settings/config' as config;

.hero {
  aspect-ratio: 27/16;
  border-radius: 8px;
  background-size: cover;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    aspect-ratio: 25/9;
    max-width: 820px;
  }

  & > div:first-child {
    width: 153%;
    aspect-ratio: 1;
    position: absolute;
    left: -100%;
    top: -100%;
    border-radius: 50%;
    background: rgba(49, 49, 49, 0.65);
    margin-left: 16px;
    margin-top: 16px;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      left: -110%;
      top: -200%;
    }
  }

  & > div:last-of-type {
    position: relative;
    width: 54%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8%;
    padding: 16px;
    z-index: 1;

    @include mixins.breakpoint(map-get(config.$breakpoints, small)) {
      width: 46%;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: 40%;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: 36%;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
      width: 40%;
    }

    & > span:first-of-type {
      width: 90%;
      color: white;
      font-size: 7.3vw;
      font-weight: 700;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        font-size: 5vw;
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        font-size: 4.1vw;
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
        font-size: 40px;
      }

    }

    & > span:last-of-type {
      color: white;
      font-size: 3.8vw;
      font-weight: 500;
      line-height: 140%;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        font-size: 2.1vw;
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        font-size: 2vw;
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
        font-size: 22px;
      }
    }
  }
}