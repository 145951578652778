.navigation-popup {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 16px 0;

  & > div:first-of-type {
    padding: 0 24px 16px;
    display: flex;
    align-items: center;

    & > img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 16px;
    }

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;

      & > span:first-child {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
      }

      & > span:last-child {
        width: 100%;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  &__divider {
    display: block;
    height: 1px;
    width: calc(100% - 48px);
    margin: 0 24px;
  }

  &__item {
    position: relative;
    display: flex;
    padding: 16px 24px;
    align-items: center;
    width: calc(100% - 2px);
    margin-left: 1px;
    cursor: pointer;

    & > span:first-of-type {
      position: absolute;
      top: 0;
      right: 24px;
      bottom: 0;
      border-radius: 2px;
      width: 8px;
    }

    & > svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }

    & > span:last-of-type {
      flex: 1;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
