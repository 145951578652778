@use "../../../stylesheet/00_settings/color" as colors;
@use '../../../stylesheet/01_tools/mixin' as mixins;
@use '../../../stylesheet/00_settings/config' as config;
@use '../../../stylesheet/00_settings/tools' as tools;

.cart {
  width: 100%;
  color: map-get(colors.$text, primary);
  background: map-get(colors.$background, primary);
  box-shadow: map-get(tools.$box-shadow, sidebar);
  height: 100%;
  display: flex;
  flex-direction: column;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    width: 396px;
    background: map-get(colors.$background, secondary);
    padding: 2rem;
    gap: 1.5rem;
  }

  &__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1.5rem;
    align-items: center;
    padding: 1.5rem 1rem;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding: 0;
    }

    > div {
      display: none;
      align-items: center;
      justify-content: space-between;

      > svg {
        cursor: pointer;
      }

      > span {
        font-size: 14px;
        padding: 8px;
        font-weight: 500;
        color: map-get(colors.$text, gray);
        cursor: pointer;
        background: map-get(colors.$background, variant);
        border-radius: 4px;
      }
    }

    > span:first-of-type {
      flex: 1;
      font-size:1.5rem;
      font-weight: 500;
    }

    > span:not(:first-of-type) {
      font-size: 14px;
      padding: 8px;
      font-weight: 500;
      color: map-get(colors.$text, gray);
      cursor: pointer;
      background: map-get(colors.$background, variant);
      border-radius: 4px;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      flex-direction: column;
      align-items: flex-start;

      > div {
        display: flex;
        width: 100%;
      }

      > span:not(:first-of-type) {
        display: none;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    padding: 1rem 1rem 0;
    gap: 1rem;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding: 0;
      overflow: hidden;
    }

    > div:first-of-type {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      overflow: auto;
      padding: 0 0 1.5rem;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        padding: 0 8px 0 0;
        overflow: auto;
      }
    }

  }

  &__fees {
    flex-direction: column;
    padding: 0;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      display: flex;
      padding: 0;
    }

    > div {
      display: flex;
      padding: .5rem;

      > span:first-of-type {
        flex: 1;
      }

      > span {
        font-family: Raleway, serif;
        font-size: 14px;
        font-weight: 500;
      }
    }

    > div:last-of-type {

      > span {
        font-size: 16px;
      }
    }
  }

  &__footer {
    border-top: 1px solid map-get(colors.$border, primary);
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding: 1rem 0 0;
    }

    button {
      font-weight: bold;
    }
  }

  &__empty {
    flex: 1;
    padding: 16px;
  }

  &__delivery {
    display: flex;
    gap: 8px;
    cursor: pointer;
    padding: .8rem;
    margin:  0 1rem;
    background: map-get(colors.$background, variant);
    border-radius: 8px;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin: 0;
    }

    > div:first-of-type {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: map-get(colors.$background, secondary);

      > svg {
        width: 24px;
        height: 24px;
      }
    }

    > div:not(:first-of-type) {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 4px;
      padding: 2px 0;

      > span:first-of-type {
        font-family: Raleway, serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
      }

      > span:not(:first-of-type) {
        font-family: Raleway, serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: map-get(colors.$text, gray);
      }
    }

    > span:first-of-type {
      font-family: Raleway, serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 12px;
    }
  }
}