@use '../../../stylesheet/00_settings/color' as colors;
@use '../../../stylesheet/01_tools/mixin' as mixins;
@use '../../../stylesheet/00_settings/config' as config;

.select {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid map-get(colors.$border, primary);
  border-radius: 4px;
  background-color: transparent;
  appearance: none;
  cursor: pointer;
  font-family: Raleway, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: map-get(colors.$text, primary);

  &--relative {
    position: relative;
  }

  &__trigger {
    width: 100%;
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:focus {
      outline: none;
      border-color: #007bff;
    }
  }

  &__menu {
    z-index: 99999;
    width: 100%;
    margin-top: 44px;;
    padding: 8px 0;
    background: map-get(colors.$background, secondary);
    border: 1px solid map-get(colors.$border, primary);
    color: map-get(colors.$text, primary);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    transform: none !important;
  }

  &__item {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    color: map-get(colors.$text, primary);

    &:hover {
      background-color: map-get(colors.$background, variant);
    }

    &--selected {
      background-color: #007bff;
      color: #fff;
    }
  }

  & > div:first-of-type {
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;

    label {
      padding: 8px 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }

    > svg {
      margin: 8px;
    }
  }
}
