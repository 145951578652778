@use '../../../stylesheet/00_settings/color' as colors;
@use '../../../stylesheet/01_tools/mixin' as mixins;
@use '../../../stylesheet/00_settings/config' as config;

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  & > div {
    width: 56px;
    height: 56px;
    background: map-get(colors.$background, secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: 108px;
      height: 108px;
    }

    &:hover {
      background: map-get(colors.$background, variant);
    }

    & > img {
      width: 32px;
      height: 32px;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        width: 56px;
        height: 56px;
      }
    }
  }


  &--selected {

    & > div {
      border: 1px solid map-get(colors.$border, primary);
    }
  }

  & > p {
    color: map-get(colors.$text, primary);
    font-size: 12px;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: 16px;
    }

  }

}