@use '../../../stylesheet/01_tools/mixin' as mixins;
@use '../../../stylesheet/00_settings/config' as config;

.auth-layout {
  width: clamp(348px, 80%, 358px);
  height: 100%;
  padding: 80px 24px;
  @include mixins.center-content;

  &__back {
    position: absolute;
    top: 0;
    left: 0;
    padding: 28px;
    cursor: pointer;
    height: 80px;

    & > svg {
      width: 24px;
      height: 24px;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      position: relative;
      align-self: flex-start;
    }
  }

  &__content {
    width: 100%;
    padding: 0 24px;
    @include mixins.center-content;

    & > * {
      margin-bottom: 24px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    & > form:first-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin: 0 0 48px;
    color: white;

    & > span:first-child {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    & > span:last-child {
      font-size: 12px;
      font-weight: 400;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding: 0 24px;
    }
  }

  &__connect-with {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      height: 1px;
      width: 25%;
    }

    & > span {
      text-align: center;
      font-size: 11px;
      font-weight: 600;
      width: fit-content;
      z-index: 1;
      padding: 0 8px;
    }
  }

  &__firebase {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > * {
      padding: 4px;
    }

    & > *:first-child {
      margin-left: 48px;
    }

    & > *:last-child {
      margin-right: 48px;
    }
  }

  &__form {
    width: 100%;

    & > * {
      margin-bottom: 20px;
    }

    & > button:last-of-type {
      margin-bottom: 0;
    }
  }

  &__forgot {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    cursor: pointer;
    padding: 24px 0;
  }

  &__action {

    & > span:first-of-type {
      font-size: 12px;
      font-weight: 400;
    }

    & > span:last-of-type {
      font-size: 14px;
      font-weight: 700;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  &__footer {
    width: 100%;
    text-align: center;

    & > span {
      font-size: 11px;
      font-weight: 400;
    }
  }
}
