@use "../../stylesheet/00_settings/color" as colors;

.empty-state {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: map-get(colors.$text, gray);
  gap: 1rem;

  > svg, > img {
    color: map-get(colors.$text, primary);
    margin-bottom: 8px;
    width: 72px;
    height: 72px;
  }

  > span:first-of-type {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: map-get(colors.$text, primary);
  }

  > span:not(:first-of-type) {
    max-width: 400px;
    text-align: center;
    color: map-get(colors.$text, gray);
  }

  > button {
    max-width: 200px;
    margin-top: 1rem;
  }
}