@use '../00_settings/config' as config;

@mixin breakpoint($bp) {
  $min-width: '';

  @if map-has-key(config.$breakpoints, $bp) {
    $min-width: map-get(config.$breakpoints, $bp);
  } @else if (type-of($bp) == number) {
    $min-width: $bp;
  }

  @media screen and (min-width: $min-width + 'px') {
    @content;
  }
}

@mixin center-content() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
