@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.menu-grid {
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > span {
    color: map-get(colors.$text, primary);
    font-size: 24px;
    font-weight: 500;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-auto-flow: column;
    gap: 24px;
    padding-bottom: 12px;
    overflow-x: auto;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      grid-auto-flow: row;
      overflow-x: visible;
    }
  }
}