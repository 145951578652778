@use '../../../stylesheet/01_tools/mixin' as mixins;
@use '../../../stylesheet/00_settings/config' as config;
@use '../../../stylesheet/00_settings/tools' as tools;

.page-menu {
  display: flex;
  height: 100%;
  width: 100%;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow-x: auto;

    & > div {
      display: flex;
      flex-direction: column;
      padding: 16px;
      gap: 32px;
      flex: 1;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        padding: 24px;
      }

      & > div:first-child {
        display: flex;
        gap: 24px;
        align-items: center;

        & > button:last-child {
          flex: 0;
        }

        & > svg {
          padding: 8px;
          width: 40px;
          height: 40px;
          border-radius: 8px;
        }
      }

      & > .promotions {
        display: flex;

        @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
          display: none;
        }
      }
    }
  }

  &__menus {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 80px;
  }

  &__action-button {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    &--fab {
      position: absolute;
      width: fit-content;
      right: 1rem;
      bottom: calc(1rem + 85px);
      box-shadow: map-get(tools.$box-shadow, default);
      height: 48px;
      z-index: 2;
    }
  }

  &--cross-fade-leave {
    opacity: 1;
  }

  &--cross-fade-leave &--cross-fade-leave-active {
    opacity: 0;
    transition: opacity 1s ease-in;
  }

  &--cross-fade-enter {
    opacity: 0;
  }

  &--cross-fade-enter &--cross-fade-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in;
  }

  &--cross-fade-height {
    transition: height .5s ease-in-out;
  }

  &__empty {
    width: 100%;
    height: 100%;
    flex: 1;
  }
}
