@use "../../stylesheet/00_settings/color" as colors;
@use '../../stylesheet/01_tools/mixin' as mixins;
@use '../../stylesheet/00_settings/config' as config;

.quantity-selector {
  display: flex;
  padding: 0 16px;
  gap: 16px;
  background: map-get(colors.$background, variant);
  color: map-get(colors.$text, primary);
  border-radius: 8px;
  height: 42px;
  min-height: 42px;
  align-items: center;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    min-height: 32px;
    height: 32px;
    padding: 0 12px;
    gap: 12px;
  }

  > span {
    text-align: center;
    font-family: Raleway, serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 12px;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: 14px;
    }
  }

  > svg {
    width: 20px;
    height: 20px;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: 16px;
      height: 16px;
    }

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}