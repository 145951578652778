
@use '../00_settings/tools' as tools;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Places autocomplete

.pac-container {
  z-index: 999999;
  margin-top: 8px;
  border-radius: 8px;
  border-top: none;
  background: #424242 none;
  padding: 4px;
  display: block;
  box-shadow: map-get(tools.$box-shadow, sidebar);

  > .pac-item {
    border-top: none;
    background: #424242;
    height: 32px;
    color: #bdbdbd;

    > .pac-item-query {
      color: #fff;
    }
  }
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

#root {
  height: 100%;
}

/* Remove list styles on UL and OL elements */

ul,
ol {
  list-style: none;
}

/* Remove hyperlinks text decoration */

a {
  text-decoration: none;
}

/* Remove default button styling */

button {
  border: none;
  padding: 0;
  margin: 0;
}

/* Remove or style the blue touch highlight on mobile devices */

* {
  -webkit-tap-highlight-color: #ffffff10;
  -webkit-user-select: none;
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px #616161 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

/* width */

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */

*::-webkit-scrollbar-track {
  background: #424242;
}

/* Handle */

*::-webkit-scrollbar-thumb {
  background: #616161;
}
