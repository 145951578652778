@use '../../../../stylesheet/00_settings/color' as colors;
@use '../../../../stylesheet/01_tools/mixin' as mixins;
@use '../../../../stylesheet/00_settings/config' as config;

.custom-meal-item {
  display: flex;
  gap: 8px;
  width: 100%;
  position: relative;

  > div:first-of-type {
    width: 60px;
    height: 60px;
    background: map-get(colors.$background, variant);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    min-width: 60px;
    min-height: 60px;

    > img {
      width: 32px;
      height: 32px;
    }
  }

  > div:not(:first-of-type) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0;
    overflow: hidden;

    > label {
      color: map-get(colors.$text, primary);
      font-family: Raleway, serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
    }

    .select__menu {
      transform: translate3d(68px, 24px, 0) !important;
      max-width: 60%;
    }
  }

  > div:last-of-type {
    max-width: 30%;
    flex: 1;

    .select__menu {
      transform: translate3d(100%, 24px, 0) !important;
      width: 50%;
    }
  }

  .form-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0;
    overflow: hidden;
    align-items: self-start;
    border-radius: 0;
    background: transparent !important;

    > label {
      color: map-get(colors.$text, primary);
      font-family: Raleway, serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
    }

    input {
      display: flex;
      height: 40px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border: 1px solid #616161;
      border-radius: 4px;
      background-color: transparent;
      appearance: none;
      cursor: pointer;
      font-family: Raleway, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: #fff;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}