@use "../../stylesheet/00_settings/color" as colors;
@use '../../stylesheet/01_tools/mixin' as mixins;
@use '../../stylesheet/00_settings/config' as config;

.sidebar {
  display: none;
  height: 100%;
  width: 388px;
  border-left: 1px solid map-get(colors.$background, secondary);
  padding: 32px;

  @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__about {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and (height <= 800px) {
      display: none;
    }

    > span {
      color: map-get(colors.$text, primary);
      font-size: 20px;
      font-weight: 500;
    }

    > span:last-of-type {
      color: map-get(colors.$text, gray);
      font-size: 15px;
      font-weight: 400;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    > span {
      color: map-get(colors.$text, primary);
      font-size: 20px;
      font-weight: 500;
    }

    > div {
      display: flex;
      justify-content: space-between;

      > a {
        text-decoration: none;
        transition: transform ease-in-out 0.2s;

        &:hover {
          transform: scale(1.3);
        }

        > svg {
          color: map-get(colors.$text, primary);
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}