@use '../../stylesheet/00_settings/color' as color;

.cart-total {
  padding: 8px;
  background: map-get(color.$background, variant);
  min-width: 100px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    color: map-get(color.$text, primary);
    margin-left: 8px;
  }

  & > svg {
    color: map-get(color.$text, primary);
  }

  &--active {
    background: map-get(color.$accent, primary);

    & > span {
      font-weight: 600;
    }
  }
}
