@use '../../stylesheet/01_tools/mixin' as mixins;
@use '../../stylesheet/00_settings/config' as config;
@use '../../stylesheet/00_settings/tools' as tools;

.navigation {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    position: relative;
    width: 93px;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: map-get(tools.$box-shadow, default);
    z-index: 999;

    & > *:last-child {
      position: relative;
      flex: 1;
      display: flex;
      align-items: flex-end;

      & > div {
        position: relative;
        width: 100%;
        height: fit-content;
      }
    }

    & + section {
      position: absolute;
      top: 0;
      left: 93px;
      width: calc(100% - 93px);
      height: 100%;
      overflow: hidden;
    }
  }

  & > svg {
    display: none;
    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      display: block;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  &__popup {

    &--mobile {
      width: 100%;
      height: fit-content;
      position: absolute;
      left: 0;
      bottom: -266px;
      transition: bottom 0.5s ease-in-out;

      & > * {
        z-index: 1000;
        position: relative;
      }

      &--visible {
        bottom: 0;
      }
    }
  }

  &__overlay {
    position: fixed;
    inset: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > *:not(:first-child) {
      flex: 1;
      display: flex;
      height: calc(100% - 128px);
      padding-bottom: 85px;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        height: calc(100% - 72px);
        padding-bottom: 0;
      }
    }
  }

  &__sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 9999;
  }
}
