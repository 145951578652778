@use "../../../stylesheet/00_settings/color" as colors;
@use "../../../stylesheet/00_settings/config" as config;
@use "../../../stylesheet/01_tools/mixin" as mixins;

.menu-list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > span {
    color: map-get(colors.$text, primary);
    font-size: 24px;
    font-weight: 500;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 500px));
    gap: 24px;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      grid-template-columns: repeat(2, minmax(327px, 1fr));
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, xx-large)) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}