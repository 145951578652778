@use "../../../../stylesheet/00_settings/color" as colors;

.order-status {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  gap: 1rem;
  color: map-get(colors.$text, primary);
  align-items: center;

  > h1 {
    font-size: 24px;
    font-weight: 700;
  }

  > span:first-of-type {
    color: map-get(colors.$text, gray);
    text-align: center;
    margin-bottom: 1rem;
  }

  > svg {
    margin-bottom: 1rem;
  }

  button {
    font-weight: bold;
    height: 48px;
    font-size: 16px;
    background: #4CAF50;
  }

  &__success {
    color: #4CAF50;
  }

  &__failed {
    color: #FF9800;
  }

}