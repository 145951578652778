.login {
  width: 100%;
  height: 100%;

  &__overlay {
    width: 100%;
    height: 100%;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
