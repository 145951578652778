@use "../../../stylesheet/00_settings/color" as colors;

.order {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  gap: 1rem;
  color: map-get(colors.$text, primary);

  > h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  > div {
    background: #00000030;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;

    > div {
      display: flex;
      justify-content: space-between;
      flex: 1;
      width: 100%;

      > span:first-of-type {
        color: map-get(colors.$text, gray);
      }

      > span:not(:first-of-type) {
        font-weight: 500;
      }
    }
  }

  button {
    font-weight: bold;
    height: 48px;
    font-size: 16px;
  }

}