@use '../../../stylesheet/01_tools/mixin' as mixins;

.navigation-segment {
  width: 93px;
  height: auto;
  @include mixins.center-content;

  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 16px;
  cursor: pointer;

  &__image {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  &__badge {
    width: 64px;
    height: 32px;
    margin-bottom: 8px;
    position: relative;
    opacity: 1;
    transition: opacity 500s;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div:first-child {
      width: 64px;
      height: 32px;
      border-radius: 16px;
    }
  }

  &__notification {
    @include mixins.center-content;

    position: absolute;
    right: 16px;
    top: 2px;
    height: 16px;
    border-radius: 8px;
    padding: 0 3px;
    min-width: 16px;

    & > span {
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      text-align: center;
    }
  }

  & > span:last-of-type {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  &__anim-enter {
    animation: enter 300ms forwards ease-out;
  }

  &__anim-exit {
    animation: exit 300ms forwards ease-in;
  }
}

@keyframes enter {

  0% {
    opacity: 0;
    width: 32px;
  }

  100% {
    opacity: 1;
    width: 64px;
  }
}

@keyframes exit {

  0% {
    opacity: 1;
    width: 64px;
  }

  100% {
    opacity: 0;
    width: 32px;
  }
}
