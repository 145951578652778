@use "../../../../stylesheet/00_settings/color" as colors;
@use '../../../../stylesheet/01_tools/mixin' as mixins;
@use '../../../../stylesheet/00_settings/config' as config;

.google-address-select {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: map-get(colors.$text, primary);
  padding: 1rem;
  max-height: 100%;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    padding: 0;
  }

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
  }

  > h1 {
    font-size: 24px;
    font-weight: 700;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  }

  &__map {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  &__location {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > div {
      display: flex;
      gap: 12px;
      background: map-get(colors.$background, variant);
      padding: 12px;
      border-radius: 8px;
      align-items: center;

      > div {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: map-get(colors.$background, secondary);

        > svg {
          width: 16px;
          height: 16px;
        }
      }

      > span {
        color: map-get(colors.$text, gray);
        font-size: 14px;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 12px;

    > h2 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    > textarea {
      font-family: Inter, serif;
      max-height: 64px;
      max-width: 100%;
      min-height: 64px;
      min-width: 100%;
      width: 100%;
      border: none;
      outline: none;
      color: inherit;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      padding: 8px 12px;
      border-radius: 8px;
      background: map-get(colors.$background, variant);
    }

    & textarea::placeholder {
      opacity: 0.8;
      color: white;
      font-weight: 200;
    }

  }

  & .error {
    color: map-get(colors.$accent, primary);
    font-size: 12px;
  }

  > button {
    height: 48px;
  }

  > button:first-of-type {
    margin-top: 8px;
  }
}