
.promotion-item {
  width: 100%;
  background-size: cover;
  height: auto;
  aspect-ratio: 2.63;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  padding: 12px;
  max-width: 400px;
  cursor: pointer;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 62%;
    height: 100%;
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  & > span {
    z-index: 1;
    font-family: Roboto, serif;
    color: white;

    &:first-of-type {
      font-size: 40px;
      font-weight: 900;
    }

    &:nth-of-type(2) {
      font-size: 20px;
      font-weight: 700;
    }

    &:last-of-type {
      font-size: 12px;
      font-weight: 500;
      margin-top: auto
    }
  }
}