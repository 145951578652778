.fire {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  &__canvas {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
}
