.carousel {
  width: 100%;
  height: 100%;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__slide-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__slide-item {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (width <= 768px) {

    &__slide-enter {
      opacity: 0;
      transform: translateX(100%);
    }

    &__slide-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition:
        opacity 500ms,
        transform 500ms;
    }

    &__slide-exit {
      opacity: 1;
      transform: translateX(0);
    }

    &__slide-exit-active {
      opacity: 0;
      transform: translateX(-100%);
      transition:
        opacity 500ms,
        transform 500ms;
    }
  }

  @media (width >= 769px) {

    &__slide-enter {
      opacity: 0;
      transform: scale(0.5) translateX(25%);
    }

    &__slide-enter-active {
      opacity: 1;
      transform: scale(1) translateX(0);
      transition:
        opacity 500ms,
        transform 500ms;
    }

    &__slide-exit {
      opacity: 1;
      transform: scale(1) translateX(0);
    }

    &__slide-exit-active {
      opacity: 0;
      transform: scale(0.5) translateX(-25%);
      transition:
        opacity 500ms,
        transform 500ms;
    }
  }
}
