.dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;

  &__overlay {
    position: fixed;
    inset: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__content {
    z-index: 999;
    inset: 0;
    position: relative;
    width: clamp(248px, 80%, 324px);
    height: auto;
    padding: 24px;
    border-radius: 8px;
  }

  & > svg {
    margin-bottom: 24px;
  }

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & > h1 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    margin-bottom: 24px;
  }

  & > span {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    margin-bottom: 24px;
  }

  & > div:last-of-type {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > button:first-of-type:not(:only-child) {
      margin-bottom: 16px;
    }
  }
}
