
.create-meal-modal {
  $block: &;

  &--mobile {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -100%;
    transition: bottom 0.5s ease-in-out;
    height: 100%;

    #{$block}__overlay {
      position: fixed;
      inset: 0;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
    }

    &--visible {
      bottom: 0;
    }

    #{$block}__content {
      width: 100%;
      height: 90%;
      border-radius: 8px;
      padding: 16px 0;
      position: absolute;
      bottom: 0;
      z-index: 9999;
      min-width: 300px;
    }
  }
}