@use "../../../../stylesheet/00_settings/color" as colors;

.menu-list-item {
  display: flex;
  gap: 12px;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > div {
      display: flex;
      justify-content: space-between;
      gap: 24px;
    }

    & > div:last-of-type {
      flex: 1;
      align-self: flex-end;
    }
  }

  & > img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
  }

  &__name {
    font-size: 14px;
    font-weight: 700;
    color: map-get(colors.$text, primary)
  }

  &__price {
    font-size: 14px;
    font-weight: 700;
    color: map-get(colors.$text, primary);
    white-space: nowrap;
  }

  &__description {
    font-size: 11px;
    font-weight: 400;
    color: map-get(colors.$text, gray)
  }

  &__add {
    color: map-get(colors.$text, gray);
    min-width: 24px;
    align-self: flex-end;
    cursor: pointer;
  }

  &__quantity {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 4px;

    > svg {
      color: map-get(colors.$accent, primary);
      align-self: center;
      cursor: pointer;
    }
  }
}