@use '../../stylesheet/01_tools/mixin' as mixins;
@use '../../stylesheet/00_settings/config' as config;
@use '../../stylesheet/00_settings/color' as color;

.header {
  padding: 16px;
  background: map-get(color.$background, secondary);
  height: 128px;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    height: 72px;
  }

  & > div:first-child {
    display: flex;
    padding-bottom: 16px;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding: 0;
    }
  }

  &__search {
    display: flex;
    gap: 1rem;

    > div {
      flex: 1;
    }

    & > svg {
      padding: 8px;
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }
  }

  &__welcome {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > span:first-of-type {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: map-get(color.$text, gray);
      margin-bottom: 8px;
    }

    & > span:last-of-type {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      color: map-get(color.$text, primary);
    }
  }
}
