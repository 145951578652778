@use "../../../stylesheet/00_settings/color" as colors;

.create-meal {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  gap: 1.5rem;
  color: map-get(colors.$text, primary);
  max-height: 100%;
  overflow: auto;

  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    > h1 {
      font-size: 24px;
      font-weight: 700;
    }

    > span {
      font-size: 14px;
      color: map-get(colors.$text, gray);
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > *:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      > div {
        width: fit-content;
        align-self: flex-end;
        min-height: 42px;
      }

      > span {
        font-size: 20px;
        font-weight: 500;
      }
    }

    > form {
      gap: 1.5rem;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: 1px solid map-get(colors.$border, primary);
    padding-top: 1.5rem;

    > div {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: .5rem;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .5rem;

        > span:first-of-type {
          font-size: 20px;
          font-weight: 600;
          line-height: 12px;
        }

        > span:not(:first-of-type) {
          text-align: right;
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
        }
      }

      > span {
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}