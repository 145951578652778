@use '../../stylesheet/00_settings/color' as colors;
@use '../../stylesheet/01_tools/mixin' as mixins;
@use '../../stylesheet/00_settings/config' as config;

.categories {
  flex: 0 1;
  gap: 24px;
  display: flex;
  flex-direction: column;

  & > h2 {
    color: map-get(colors.$text, primary);
    font-size: 24px;
    font-weight: 500;
  }

  &__items {
    display: flex;
    gap: 12px;
    flex-flow: row wrap;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      gap: 24px;
    }
  }

}