.login-options {
  width: clamp(300px, 80%, 310px);
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: white;
    text-align: center;

    & > span:first-child {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    & > span:last-child {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 8px;
    }
  }

  &__connect-with {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      height: 1px;
      width: 25%;
    }

    & > span {
      text-align: center;
      font-size: 11px;
      font-weight: 600;
      width: fit-content;
      z-index: 1;
      padding: 0 8px;
    }
  }

  &__firebase {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > * {
      padding: 4px;
    }

    & > *:first-child {
      margin-left: 48px;
    }

    & > *:last-child {
      margin-right: 48px;
    }
  }

  &__footer {
    margin-top: 32px;

    & > span {
      font-size: 11px;
      font-weight: 400;
    }
  }

  & > *:first-child {
    width: clamp(200px, 80%, 300px);
    height: clamp(142px, calc(80% * 7 / 10), 212px);
    margin-bottom: 72px;
  }

  & > * {
    margin-bottom: 24px;
  }

  & > button:first-of-type {
    margin-bottom: 16px;
  }

  & > button:last-of-type {
    margin-bottom: 32px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
